* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* background: #080c12; */
  background-image: linear-gradient(to right, #151515 , #000);
  /* background-color: #101010; */

  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Rubik';
  font-size: 40px;
  color: #fff;
  font-weight: 400;
}

h2 {
  font-family: 'Rubik';
  font-size: 38px;
  color: #fff;
  font-weight: 400;
  line-height: 1.4;
}

h3 , h4 , h5 {
  font-family: 'Rubik';
  font-size: 36px;
  color: #fff;
  font-weight: 400;
}

p {
  font-family: 'Rubik';
  font-size: 16px;
  color: #fff;
  line-height: 1.4;
}

a{
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  text-decoration-line: none;
  text-decoration-color: rgba(255, 255, 255, 0.3);
  transition: all 0.4s;
}
a:hover{
  color: rgba(255, 255, 255,0.8);
}

span{
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
  cursor: default;
}

button{
  display: flex;
  height: 52px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(170, 170, 170,0.1);
  border: 1px solid rgba(170, 170, 170,0.1);
  border-radius: 10px;
  transition: all 0.5s;
  text-decoration: none;
  cursor: pointer;
}

button:hover{
  background: rgba(170, 170, 170,0.3);
  border: 1px solid rgba(170, 170, 170,0.1);
}

.btn-content{
  width: 50%;
}
.btn-down{
    display: flex;
    max-width: 100%;
    height: 52px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background: rgba(170, 170, 170,0.1);
    border: 1px solid rgba(170, 170, 170,0.3);
    border-radius: 10px;
    transition: all 0.5s;
    text-decoration: none;
    cursor: pointer;
  }

.btn-down:hover{
    background: rgba(170, 170, 170,0.3);
    border: 1px solid rgba(170, 170, 170,0.1);
}

code {
  font-family: 'Rubik';
}

@media only screen and (max-width: 789px){

  h1 { 
    font-size: 30px;
  }

  h2, h3 , h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }

  a.btn-down{
    font-size: 16px;
  }

}

  @media only screen and (max-width: 400px){

    h1 { 
      font-size: 24px;
    }
  
    h2, h3 , h4 {
      font-size: 22px;
    }
  
    h5 {
      font-size: 22px;
    }
  
    a.btn-down{
      font-size: 16px;
    }
  

}
