.container-footer{
    display: flex;
    width: 100%;
    height: 5rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: 0.1rem 0;
}

.content-footer{
    display: flex;
    padding: 10px;
    justify-content: center;
}

.copy{
    font-size: 12px;
    color: #aaaaaa;
    transition: 0.5s;
    cursor: default ;
}

.copy:hover{
    color: #fff;
}

.v{
    font-size: 10px;
    color: rgba(107, 107, 107, 0.3);
}
