.container-contact{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 8rem 0;
}

.content-contact{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.content-contact-text{
    width: 100%;
    display: flex;
    margin-bottom: 5rem;
    justify-content: center;
}

.content-contact-image a{
    display: flex;
    justify-content: center;
}

.content-contact-image{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4rem;

}
.content-contact-image img {
    cursor: pointer;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 40%;
}
.content-contact-image img:hover {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.p-contact{
    color: #aaaaaa;
}

@media only screen and (max-width: 789px){

.container-contact{
    margin: 6rem 0;
    padding: 0 20px;
}

.content-contact-image{
    gap: 1.8rem;
}
.content-contact-image img{
    width: 42px;
}
    
}

@media only screen and (max-width: 400px){
    .content-contact-image{
        gap: 1rem;
    }
}
