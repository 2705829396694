.container-skills{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 20px;
}
.txt{
    width: 30rem;
    display: flex;
    flex-direction:column;
    gap: 20px;
}

.skill-camp{
    display: flex;
    flex-wrap: wrap;
    flex-direction:row;
    gap: 8px;
}
.skill-box{
    display: flex;
    background: rgba(170, 170, 170,0.1);
    padding: 10px;
    border-radius: 10px;
    transition: all 0.5s;
}
.skill-box:hover{
    background: rgba(170, 170, 170,0.3);
}

.asset{
    position: relative;
    width: 35rem;
    height: 30rem;
}

img.blur{
    position: absolute;
    max-width: 500px;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    filter: blur(2rem);
}

.techs{
    position: absolute;
    top: 0px;
    left: 30px;
    z-index: 0;
    display: grid;
    grid-template-columns: repeat(3, 10rem);
    gap: 1rem;
    place-content: center;
    place-items: center;
    width: 100%;
    height: 100%;
}

.tech{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 7rem;
    background: rgba(170, 170, 170,0.1);
    border-radius: 2rem;
    transition: all 0.5s;
}

.tech:hover{
    background: rgba(170, 170, 170,0.3);
}

.tech:nth-child(1){
    transform: translate(30%, -20%) scale(0.9) translateY(2rem);
}

.tech:nth-child(2){
    transform: translate(0px, -2rem) scale(1.2);
}
.tech:nth-child(3){
    transform: translate(-25%, -25%) scale(0.8) translateY(1rem);
}
.tech:nth-child(4){
    transform: translate(25px, 0px) translateX(-1rem);
}
.tech:nth-child(5){
    transform: translate(20px, 0px) translateY(-1rem) translateX(-2rem);
}
.tech:nth-child(6){
    transform: translate(0px, 0px);
}
.tech:nth-child(7){
    transform: translate(35%, 0%) translateX(2rem) translateY(0px) scale(1.1);
}
.tech:nth-child(8){
    transform: translate(35px, -10px) scale(0.7);
}
.tech:nth-child(9){
    transform: translate(-15%, 35%) scale(1.1);
}

@keyframes flutuacao {
    0%, 100%{
        transform: translateY(0px);
    }
    50% {
        transform: translatex(-20px);
    }
    
}

@media only screen and (max-width: 1180px){

    .container-skills {
        flex-direction: column;
        text-align: center;
        padding: 3rem 20px;
    }

    .skill-camp{
        justify-content: center;
    }

    .txt{
        width: 100%;
    }
    .asset{
        display: none;
        width: 100%;
        height: 30rem;
    }
    img.blur{
        max-width: 100%;
        width: 100%;
        left: 50%;
    }

    .techs{
        left: 0;
        height: 110%;
    }

    .tech:nth-child(6){
        transform: translate(-20px, 7px);
    }
    .tech:nth-child(8){
        transform: translate(20px, -10px) scale(0.7);
    }
    .tech:nth-child(9){
        transform: translate(-25%, 25%) scale(1.1);
    }


}
