.container{
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}


html{
  scroll-behavior: smooth;
}


